<div class="main-wrapper">

	<div [@fadeInOutAnimationJS] *ngIf="app.isLoading && app?.isPlatformBrowserVar" class="Loadinglayout">
		<!-- <img [ngClass]="this.app?.getUserLanguage() == 'en' ? 'rl' : 'lr'" class="imgLoading" src="./assets/img/XOsX.gif" alt="loading" /> -->
		<!-- <div class="loaderWrapper">
			<div class="lds-ripple"><div></div><div></div></div>
		</div> -->
		<img class="imgLoading" src="./assets/img/icons/bigLogo.png" alt="loading" />

		<img class="imgLoadingDualRing" src="./assets/img/icons/dualRing.png" alt="loading" />
	</div>


	<app-headerboot class="headerClass" *ngIf="app?.isPlatformBrowserVar"></app-headerboot>

	<main>
		<router-outlet></router-outlet>
	</main>

	<app-footer *ngIf="app?.isPlatformBrowserVar"></app-footer>
</div>