import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, } from '@angular/router';
import { AppService } from './app.service';
import { DOCUMENT, DatePipe, isPlatformBrowser } from '@angular/common';
import AOS from 'aos';
import { Title } from '@angular/platform-browser';
import { fadeInOutAnimationJS } from './animations/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe],
  animations: [fadeInOutAnimationJS]
})

export class AppComponent implements OnInit {

  constructor(
    public Router: Router,
    public app: AppService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private title: Title,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (window?.location?.pathname.includes('/ar/')) {
        this.translate.use('ar');
        document.dir = 'rtl';
        localStorage.setItem('Direction', 'rtl');
        this.app.languageCode = 'ar'
        localStorage.setItem("Language", 'ar');
      } else if (window?.location?.pathname.includes('/en/')) {
        this.translate.use('en');
        document.dir = 'ltr';
        localStorage.setItem('Direction', 'ltr');
        this.app.languageCode = 'en'
        localStorage.setItem("Language", 'en');
      } else {
        if (!this.app.getUserDire()) {
          this.translate.use('ar');
          localStorage.setItem('Direction', 'rtl');
          document.dir = 'rtl';
          this.app.languageCode = 'ar'
          localStorage.setItem("Language", 'ar');
        } else {
          this.translate.use(this?.app?.getUserLanguage());
          document.dir = this!.app!.getUserDire();
        }
      }
      // if (!this.app.getUserDire()) {
      //   this.Router.events.subscribe((e) => {
      //     if (e instanceof NavigationEnd) {
      //       if (e?.url?.includes('/ar/')) {
      //         localStorage.setItem("Direction", 'rtl');
      //         document.dir = 'rtl'
      //       } else if (e?.url?.includes('/en/')) {
      //         localStorage.setItem("Direction", 'ltr');
      //         document.dir = 'ltr'
      //       } else {
      //         localStorage.setItem("Direction", 'ltr');
      //         document.dir = 'ltr'
      //       }
      //     }
      //   })
      // } else if (this.app.getUserDire()) {
      //   document.dir = this!.app!.getUserDire()
      // }
    }
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      // Init Animations on scroll
      AOS.init()
      this.app.getConfig();
    }
    // Handle Title And Link Element in header Dynamically 
    this.handleTitleAndLink()
  }

  // Handle Title through entire routes if title is not provided in component >> check app routing module
  handleTitleAndLink() {
    this.Router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        let langServerSideCode = event?.url?.includes('/ar/') ? 'ar' : 'en';
        const lang = isPlatformBrowser(this.platformId) ? this?.app?.languageCode : langServerSideCode
        const title = this.getTitle(this.route.root);
        // handle link element changes on header

        // #TODO**********************************************************************************************************************
        this.linkEdit(event?.url)
        if (title['ar'] || title['en']) {
          this.title.setTitle(title[lang] || title['en']);
        } else {
          // Else doing nothing because if dataTitle not provided in app-routing-modules it will be provided in component(dynamic TITLE)
          // if (lang == 'en') {
          //   this.title.setTitle("32g23r23");
          // } else {
          //   this.title.setTitle("32g23r23");
          // }
        }
      }
    });
  }

  linkEdit(url) {
    let decodedUrl = decodeURIComponent(url);
    let linkEn = this.document.getElementById("linkSightEn")
    let linkAr = this.document.getElementById("linkSightAr")
    linkEn.setAttribute('href', `https://www.Elite.com/en/${decodedUrl.slice(4, decodedUrl.length)}`);
    linkAr.setAttribute('href', `https://www.Elite.com/ar/${decodedUrl.slice(4, decodedUrl.length)}`);
  }

  getTitle(route: ActivatedRoute): any {
    const title = route.snapshot.data['title'];
    if (route.firstChild) {
      return { ...title, ...this.getTitle(route.firstChild) };
    } else {
      return title;
    }
  }
}

