import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InternationalizationComponent } from './internationalization/internationalization.component';

const routes: Routes = [
  { path: '', redirectTo: `${localStorage.Language ? localStorage.Language : 'ar'}/home`, pathMatch: 'full' },

  {
    path: ':languageCode',
    component: InternationalizationComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        data: { title: { en: 'Elite | Home', ar: 'Elite | الرئيسية' } }
      },
      {
        path: 'about-us',
        loadChildren: () => import('./about-us/about.module').then((m) => m.AboutModule),
        data: { title: { en: 'Elite | About Us', ar: 'Elite | عنا' } }
      },
      {
        path: 'why-elite',
        loadChildren: () => import('./why-elite/why.module').then((m) => m.WhyModule),
        data: { title: { en: 'Elite | Why Elite ', ar: 'Elite | لماذا ايليت' } }
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./contact-us/contact.module').then((m) => m.ContactModule),
        data: { title: { en: 'Elite | Contact', ar: 'Elite | تواصل معنا' } }
      },
      {
        path: 'locations',
        loadChildren: () => import('./locations/locations.module').then((m) => m.LocationsModule),
        data: { title: { en: 'Elite | Locations', ar: 'Elite | المواقع' } }
      },
      {
        path: 'careers',
        loadChildren: () => import('./career/career.module').then((m) => m.CareerModule),
        data: { title: { en: 'Elite | Careers', ar: 'Elite | وظائف' } }
      },
      {
        path: 'services',
        loadChildren: () => import('./services/services.module').then((m) => m.ServicesModule),
        data: { title: { en: 'Elite | Services', ar: 'Elite | الخدمات' } }
      },
      {
        path: 'services-details/:serviceId',
        loadChildren: () => import('./services/service-details/service-details.module').then((m) => m.ServiceDetailsModule),
        data: { title: { en: 'Elite | Service', ar: 'Elite | الخدمة' } }
      },
      // {
      //   path: 'business',
      //   loadChildren: () => import('./business/business.module').then((m) => m.BusinessModule),
      //   data: { title: { en: 'Elite | Business', ar: 'Elite | الاعمال' } }
      // },
      {
        path: 'articles',
        loadChildren: () => import('./articles/article.module').then((m) => m.ArticleModule),
        data: { title: { en: 'Elite | Articles', ar: 'Elite | المقالات' } }
      },
      {
        path: 'portal',
        loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
        data: { title: { en: 'Elite | Portal', ar: 'Elite | بوابة العميل' } }
      },
      // {
      //   path: 'products',
      //   loadChildren: () => import('./products/products.module').then((m) => m.ProductsModule),
      //   data: { title: { en: 'Elite | Products', ar: 'Elite | المنتجات' } }
      // },
      // {
      //   path: 'login',
      //   loadChildren: () => import('./auth/login/login.module').then((m) => m.LoginModule),
      //   data: { title: { en: 'Elite | Login', ar: 'Elite | تسجيل الدخول' } }
      // },
      {
        path: 'policies',
        loadChildren: () => import('./policy/all-terms.module').then((m) => m.AllTermsModule)
      },
      { path: '**', redirectTo: 'home', pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: `${localStorage.Language ? localStorage.Language : 'ar'}/home`, pathMatch: 'full' },
  {
    path: '**',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
      // scrollPositionRestoration: "enabled",
      initialNavigation: 'enabledBlocking'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
