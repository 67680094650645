import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppService } from 'src/app/app.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonService } from '../common.service';
import { fadeInOutAnimationJS, fadeUpAnimationJS, slideFromUp } from 'src/app/animations/animations';

@Component({
  selector: 'app-headerboot',
  templateUrl: './headerboot.component.html',
  styleUrls: ['./headerboot.component.css'],
  animations: [slideFromUp],
  // enterFromRightLeaveToLeftAnimation(localStorage.Language)
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderbootComponent implements OnInit, OnDestroy {

  headerMenu: boolean = false
  selectedLang
  navbarType: "navbarWithScroll" | "navbarWithoutScroll" = "navbarWithoutScroll"
  addScrollListener = () => window.addEventListener('scroll', this.onScrolling);
  removeScrollListener = () => window.removeEventListener('scroll', this.onScrolling);
  navMode: "blue" | "white" = "white"

  constructor(
    public app: AppService,
    public router: Router,
    public appCompon: AppComponent,
    public route: ActivatedRoute,
    private service: CommonService,
    private clipboard: Clipboard,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.removeScrollListener();
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.addScrollListener();
      this.checkNavbarMode()
    }
    setTimeout(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.checkNavbarMode()
      }
      this.selectedLang = this?.router?.url?.includes('/ar/') ? 'ar' : 'en';
    }, 100);
    this.selectedLang = this!.app!.getUserLanguage()
  }

  checkNavbarMode() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url.includes('locations') || e.url.includes('careers') || e.url.includes('policies') || e.url.includes('articles/details')) {
          this.navMode = 'blue'
        } else {
          this.navMode = 'white'
        }
      }
    })
  }

  // privateFuns
  setLanguage(e) {
    this.app.showLoading();
    setTimeout(() => {
      let x = this.app.getUserLanguage()
      if (e == 'en' && x != 'en') {
        this.router.navigateByUrl(this.router.url.replace('ar', 'en'));
        localStorage.setItem("Language", 'en');
        localStorage.setItem("Direction", 'ltr');
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            window.location.reload();
            // this.app.dismissLoading();
          }, 100);
        }
      } else if (e == 'ar' && x != 'ar') {
        this.router.navigateByUrl(this.router.url.replace('en', 'ar'));
        localStorage.setItem("Language", 'ar');
        localStorage.setItem("Direction", 'rtl');
        if (isPlatformBrowser(this.platformId)) {
          setTimeout(() => {
            window.location.reload();
            // this.app.dismissLoading();
          }, 100);
        }
      }
    }, 500);
  }
  onScrolling = () => {
    let scroll = window.scrollY;
    if (scroll == 0) {
      this.navbarType = "navbarWithoutScroll"
    } else {
      if (scroll > 200) this.navbarType = "navbarWithScroll"
    }
  }

  @ViewChild('dropDownMobileNavbar', { static: false }) dropDownMobileNavbar: ElementRef;
  closeNavBarMobile(){
    if(this.dropDownMobileNavbar){
      this.dropDownMobileNavbar.nativeElement.click();
    }
  }

  // handleRoutes
  routeAndCloseMenu(locationString) {
    this.headerMenu = false
    setTimeout(() => {
      this.router.navigate(['/' + this?.app?.languageCode + locationString]);
    }, 200);
  }
  routeThenScroll(locationString, scrollId) {
    this.headerMenu = false
    this.router.navigate(['/' + this?.app?.languageCode + locationString]).then(() => {
      setTimeout(() => {
        this.scrollToElement(scrollId);
      }, 700);
    });
  }
  scrollToElement(elementId: string) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  copyEmail(e) {
    if (this?.app?.languageCode == 'en') {
      this.app.successMsg("Copied!")
      this.clipboard.copy(e);
    } else {
      this.app.successMsg("تم النسخ!")
      this.clipboard.copy(e);
    }
  }



}
