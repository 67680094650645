import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  subscribeByEmail = (req: FormData) => this.http.post<any>(environment.baseUrl + "Newsletters/SubscribeToNewsletters", req);

}
