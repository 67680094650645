import { animate, style, transition, trigger } from '@angular/animations';

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.4s ease', style({ opacity: 1 }))
    ]),
    transition(':leave', [
        animate('0.4s ease', style({ opacity: 0 }))
    ])
]);

export const fadeUpAnimationJS = trigger('fadeUpAnimationJS', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.5s ease', style({ opacity: 1, transform: 'translateY(0)' }))
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate('0.5s ease', style({ opacity: 0, transform: 'translateY(20px)' }))
    ])
]);

export const fadeInOutAnimationJS = trigger('fadeInOutAnimationJS', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease', style({ opacity: 1 }))
    ]),
    transition(':leave', [
        animate('0.5s ease', style({ opacity: 0 }))
    ])
]);


export function enterFromRightLeaveToLeftAnimation(language: string = 'en') {
    const enterFrom = language === 'ar' ? '-100%' : '100%';
    const leaveTo = language === 'ar' ? '-100%' : '100%';
    return trigger('enterFromRightLeaveToLeftAnimation', [
        transition(':enter', [
            style({ opacity: 0, transform: `translateX(${enterFrom})` }),
            animate('0.5s ease', style({ opacity: 1, transform: 'translateX(0%)' }))
        ]),
        transition(':leave', [
            animate('0.5s ease', style({ opacity: 0, transform: `translateX(${leaveTo})` }))
        ])
    ]);
}


export const slideFromUp = trigger('slideFromUp', [
    transition(':enter', [
        style({ transform: 'translateY(-100%)', opacity: 0 }),
        animate('0.5s ease', style({ transform: 'translateY(0)', opacity: 1 }))
    ]),
    transition(':leave', [
        animate('0.5s ease', style({ transform: 'translateY(-100%)', opacity: 0 }))
    ])
]);


