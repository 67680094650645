// TEST
let TestBaseUrl: string = "https://elite-ib.com/api/api/";
let TestActualUrl: string = "http://elite-ib/";
// TEST
let isLive: boolean = false;
// LIVE
// let LiveBaseUrl: string = "http://216.158.233.68/api/api/";
// let LiveActualUrl: string = "http://216.158.233.68/";
// LIVE
export const environment = {
  production: true,
  isLive: isLive,
  baseUrl: TestBaseUrl,
  actualUrl: TestActualUrl,
  appName: "Elite",
  imageBaseUrl: TestBaseUrl + "File/"
};

// Inside of the paymentForm there is LInks like the Elite icon above make sure to change it 888888.
// Note : To Switch From/To Prod/Testing Change  : (envLinks  ,<meta name="robots" content="noindex" in index.html> , { provide: APP_BASE_HREF, useValue: '/site/Elite/' }, ReWrite File links , care of iis massage when you publish (might need to change nvm use 18)) ,payment links & forms
// npm install -g gzipper *********** for html/css/js compression
