import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { environment } from "src/environments/environment";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Router } from "@angular/router";
import { Meta, Title } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpClient } from "@angular/common/http";

export class response {
  data: any;
  errorCode: number;
  errorMessage: string;
  success: boolean
}

@Injectable({
  providedIn: "root"
})
export class AppService {

  //ATTRIBUTES
  isLoading: boolean = false;

  // Handle LangCode ServerSide And ClintSide
  langServerSideCode = this?.router?.url?.includes('/ar/') ? 'ar' : 'en';
  langBrowserSideCode = localStorage.Language ? localStorage.Language : 'ar'
  languageCode = isPlatformBrowser(this.platformId) ? this?.langBrowserSideCode : this.langServerSideCode
  // Handle LangCode ServerSide And ClintSide

  isPlatformBrowserVar = isPlatformBrowser(this.platformId) ? isPlatformBrowser(this.platformId) : false
  isPlatformServerVar = isPlatformServer(this.platformId) ? isPlatformServer(this.platformId) : false

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private meta: Meta,
    private title: Title,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) { }

  showLoading() {
    this.isLoading = true
  }
  dismissLoading() {
    this.isLoading = false
  }

  dismissAfter() {
    setTimeout(() => {
      this.dismissLoading();
    }, 800);
  }

  getImageFile = (name: string) => name ? environment.imageBaseUrl + name : "assets/img/doctors/book-doctor-03.jpg"

  getUserImage(name: string) {
    if (name) {
      return environment.imageBaseUrl + name
    } else if (!name) {
      if (this?.getUserData()?.gender == 1) {
        return "assets/img/icons/male.png"
      } else if (this?.getUserData()?.gender == 2) {
        return "assets/img/icons/female.png"
      }
    }
  }

  getUserData = (): any => JSON.parse(localStorage.getItem(environment.appName + "UserData"));

  getUserLanguage = (): any => localStorage.Language;

  getUserDire = (): any => localStorage.Direction;

  updateMetaService(content) {
    this.meta.updateTag({ name: 'description', content: `${content}` });
  }

  updateTitleService(content) {
    this.title.setTitle(`${content}`)
  }

  mainLogOut() {
    localStorage.removeItem(environment.appName + "UserData");
    localStorage.removeItem(environment.appName);
    // this.checkRole();
    if (this?.getUserLanguage() == 'en') {
      this.successMsg("You've Been Logged Out")
    } else if (this?.getUserLanguage() == 'ar') {
      this.successMsg("لقد تم تسجيل الخروج")
    }
    this.router.navigate([`/${this?.languageCode}/login`]);
    // this.router.navigate(['/' + this?.languageCode + '/login']).then(() => {
    // this.cd.detectChanges();
    // });
  }


  // snackBarsHandle
  successMsg(e) {
    if (this?.languageCode == 'en') {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['snack-success']
      });
    } else {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['snack-success']
      });
    }
  }

  errorMsg(e) {
    if (this?.languageCode == 'en') {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['snack-error']
      });
    } else {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
        panelClass: ['snack-error']
      });
    }
  }

  infoMsg(e) {
    if (this?.languageCode == 'en') {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snack-info']
      });
    } else {
      this.snackBar.open(e, '', {
        duration: 2300,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['snack-info']
      });
    }
  }
  // snackBarsHandle


  routeToServiceDetails(id) {
    setTimeout(() => {
      this.router.navigate([`/${this?.languageCode}/services-details/${id}`]);
    }, 100);
  }

  routeToArticleDetails(id) {
    setTimeout(() => {
      this.router.navigate([`/${this?.languageCode}/articles/details/${id}`]);
    }, 100);
  }




  // Global Slug
  generateSlug(inputString) {
    const encodedString = decodeURIComponent(inputString);
    return encodedString
      .toLowerCase()
      .replace(/ /gi, '-')
      .replace(/-{2,}/g, '-')
      .replace(/[()،,.^+$]/gi, '');
  }
  // Global Slug

  // routeToProducts(typeName?, collectionName?) {
  //   let type = this?.languageCode == 'ar' ? 'جميع-الانواع' : 'all-types'
  //   let collection = this?.languageCode == 'ar' ? 'جميع-المجموعات' : 'all-collections'

  //   if (collectionName) {
  //     collection = this.generateSlug(collectionName)
  //   }

  //   if (typeName) {
  //     type = this.generateSlug(typeName)
  //   }

  //   setTimeout(() => {
  //     this.router.navigate([`/${this?.languageCode}/products/${type}/${collection}`]);
  //   }, 100);
  // }

  configInfo;
  getConfig() {
    this.getConfigApi().subscribe(
      res => {
        if(res.errorCode == 0){
          this.configInfo = res.data
          // console.warn(this.configInfo)
        }
      }
    )

  }

  getConfigApi = () => this.http.get<response>(environment.baseUrl + "Configuration/Get");


  routeToX(){
    if(!this.configInfo.twitterX){return}
    window.open(this.configInfo.twitterX, '_blank');
  }

  routeToLin(){
    if(!this.configInfo.facebook){return}
    window.open(this.configInfo.facebook, '_blank');
  }

  routeToCustomer(){
    if(!this.configInfo.customerPortalLink){return}
    window.open(this.configInfo.customerPortalLink, '_blank');
  }

} 